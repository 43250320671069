import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AlistsService} from '@aztrix/sdk';
import {combineLatest, ReplaySubject, switchMap} from 'rxjs';

@Component({
  selector: 'ax-alist-apple-wallet-button',
  templateUrl: './alist-apple-wallet-button.component.html',
  styleUrls: ['./alist-apple-wallet-button.component.scss'],
})
export class AlistAppleWalletButtonComponent implements OnChanges {
  @Input() alistId: string;
  @Input() alistLanguageId: string;
  @Input() alistName?: string;

  private _alistId$ = new ReplaySubject<string>(1);
  private _alistLanguageId$ = new ReplaySubject<string>(1);

  alistWallet$ = combineLatest([this._alistId$, this._alistLanguageId$]).pipe(
    switchMap(([alistId, alistLanguageId]) =>
      this._alistService.getPassbookCardForAlist(alistId, alistLanguageId)
    )
  );

  constructor(private _alistService: AlistsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.alistName && this.alistId) {
      this._alistId$.next(this.alistId);
    }
    if (changes.alistLanguageId && this.alistLanguageId) {
      this._alistLanguageId$.next(this.alistLanguageId);
    }
  }
}
