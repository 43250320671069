import {ALIST_FILE_URL} from '@alist/config';
import {Component, Inject, Optional, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {OverlayService} from '@aztrix/components/overlay';
import {QrCodeComponent} from '@aztrix/components/qr-code';
import {ALIST_VIEW_URL, UrlTransformerService} from '@aztrix/environment';
import {FeatureType} from '@aztrix/helpers';
import {AListService} from '@aztrix/list/store/alist/alist.service';
import {TierService} from '@aztrix/list/store/tier/tier.service';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {ForwardType} from '@aztrix/models';
import {AlistRepresentation} from '@aztrix/sdk';
import {TranslateService} from '@aztrix/translate';
import {BehaviorSubject, combineLatest, first, map, shareReplay} from 'rxjs';

type shareTypes = 'QR' | 'LINK';

@Component({
  selector: 'al-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss', './share.scss'],
})
export class ShareComponent {
  selectedOption: shareTypes = 'QR';

  Type = AlistRepresentation.TypeEnum;

  @ViewChild(QrCodeComponent) qrCode: QrCodeComponent;

  alistType$ = this._alistservice.serverAlist$.pipe(map((alist) => alist?.type));

  alistId$ = this._alistservice.activeAlistId$;
  alistLanguageId$ = this._alistservice.activeAlistLanguageId$;
  serverAlistLanguage$ = this._alistservice.serverAlistLanguage$;

  name$ = this._alistservice.serverAlist$.pipe(map((alist) => alist?.name));

  aListAvatarUrl$ = this.serverAlistLanguage$.pipe(
    map((alist) =>
      alist?.avatar ? this._urlTransformer.transformUrl(`${ALIST_FILE_URL}/${alist?.avatar}`) : null
    )
  );

  aListUrl$ = combineLatest([this._alistViewUrl, this.name$, this.serverAlistLanguage$]).pipe(
    map(
      ([alistViewUrl, name, alistLanguage]) =>
        `${alistViewUrl}/${name}*?lang=${alistLanguage?.language}`
    ),
    shareReplay(1)
  );

  currentTier$ = this._tierService.currentTier$;

  useAvatar = false;

  FeatureType = FeatureType;
  ForwardType = ForwardType;

  constructor(
    private _alistservice: AListService,
    private _snackbar: MatSnackBar,
    private _translateService: TranslateService,
    private _tierService: TierService,
    private _overlay: OverlayService,
    private _urlTransformer: UrlTransformerService,
    @Optional() @Inject(ALIST_VIEW_URL) private _alistViewUrl: BehaviorSubject<string>
  ) {}

  changeOption(option: shareTypes) {
    if (option === 'LINK') {
      if (navigator.canShare()) {
        this.share();
      } else {
        this.selectedOption = option;
      }
    } else {
      this.selectedOption = option;
    }
  }

  async share() {
    this.aListUrl$.pipe(first()).subscribe((url) => {
      try {
        navigator.share({
          url,
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (!(error?.name === DOMException.ABORT_ERR)) {
          this._snackbar.open(this._translateService.instant('error.unknown'), undefined, {
            duration: 2000,
          });
        }
      }
    });
  }

  close() {
    this._overlay.closeModal();
  }
}
