import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {SpinnerModule} from '@aztrix/components/spinner';
import {TranslatePipe} from '@aztrix/translate';

import {QrCodeComponent} from './qr-code.component';

@NgModule({
  declarations: [QrCodeComponent],
  exports: [QrCodeComponent],
  imports: [CommonModule, TranslatePipe, SpinnerModule, ButtonModule],
})
export class QrCodeModule {}
