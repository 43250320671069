import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ProfilesService} from '@aztrix/sdk';
import {ReplaySubject, switchMap} from 'rxjs';

@Component({
  selector: 'ax-profile-apple-wallet-button',
  templateUrl: './profile-apple-wallet-button.component.html',
  styleUrls: ['./profile-apple-wallet-button.component.scss'],
})
export class ProfileAppleWalletButtonComponent implements OnChanges {
  @Input() profileId: string;

  private _profileId$ = new ReplaySubject<string>(1);

  profileWallet$ = this._profileId$.pipe(
    switchMap((profileId) => this._profilesService.getPassbookCardForProfile(profileId))
  );

  constructor(private _profilesService: ProfilesService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.profileId && this.profileId) {
      this._profileId$.next(this.profileId);
    }
  }
}
