import {PropertiesService} from '@aztrix/list/store/properties/properties.service';
import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {EnvironmentService} from '@aztrix/environment';
import {isOfType} from '@aztrix/helpers';
import {PropertyRepresentation} from '@aztrix/sdk';
import {BehaviorSubject, first, interval, map, Observable, shareReplay} from 'rxjs';

@Component({
  selector: 'al-email-signature',
  templateUrl: './email-signature.component.html',
  styleUrls: ['./email-signature.component.scss', '../share.scss'],
})
export class EmailSignatureComponent {
  @Input() alistUrl: string;
  @Input() alistName: string;

  copied$ = new BehaviorSubject(false);

  @ViewChild('signatureContent') signatureContent: ElementRef;

  properties$ = this._properties.properties$.pipe(shareReplay(1));

  email$: Observable<PropertyRepresentation | undefined> = this.properties$.pipe(
    map((properties) =>
      properties.find((property) => isOfType(property, PropertyRepresentation.TypeEnum.EMAIL))
    )
  );

  mobile$: Observable<PropertyRepresentation | undefined> = this.properties$.pipe(
    map((properties) =>
      properties.find((property) =>
        isOfType(property, PropertyRepresentation.TypeEnum.MOBILE_PHONE)
      )
    )
  );

  firstName$: Observable<PropertyRepresentation | undefined> = this.properties$.pipe(
    map((properties) =>
      properties.find((property) => isOfType(property, PropertyRepresentation.TypeEnum.FIRST_NAME))
    )
  );

  lastName$: Observable<PropertyRepresentation | undefined> = this.properties$.pipe(
    map((properties) =>
      properties.find((property) => isOfType(property, PropertyRepresentation.TypeEnum.LAST_NAME))
    )
  );

  organisaionName$: Observable<PropertyRepresentation | undefined> = this.properties$.pipe(
    map((properties) =>
      properties.find((property) =>
        isOfType(property, PropertyRepresentation.TypeEnum.ORGANISATION_NAME)
      )
    )
  );

  viewURI$ = this._environment.string$('ALIST_VIEW_URI');

  constructor(
    private _properties: PropertiesService,
    private _environment: EnvironmentService
  ) {}

  copy() {
    if ('write' in (navigator?.clipboard || {})) {
      navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob([this.signatureContent.nativeElement.outerHTML], {
            type: 'text/html',
          }),
        }),
      ]);
    } else {
      window.getSelection()?.removeAllRanges();
      const range = document.createRange();
      range.selectNode(this.signatureContent.nativeElement);
      window.getSelection()?.addRange(range);
      document.execCommand('copy');
    }

    this.copied$.next(true);
    interval(5000)
      .pipe(first())
      .subscribe(() => {
        this.copied$.next(false);
      });
  }
}
