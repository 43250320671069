<a
  axButton
  icon-button
  filled
  class="forward-button"
  [color]="socialColor(forwardType)"
  [href]="shareLink(forwardType)"
  [target]="linkTarget(forwardType)"
>
  <ax-icon
    class="icon"
    *ngIf="forwardIcon(forwardType); else vCard"
    [name]="forwardIcon(forwardType)"
  ></ax-icon>
</a>

<ng-template #vCard>
  <span class="text-icon">vCard</span>
</ng-template>
