import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BASE_URL} from '@aztrix/environment';
import {TranslateService} from '@aztrix/translate';
import {downloadDataUrl, generateQR$} from '@aztrix/helpers';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {BehaviorSubject, first, map, tap} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'ax-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent implements OnInit, OnChanges {
  @Input() name?: string;
  @Input() avatarUrl?: string;
  @Input() url: string;
  @Input() useAvatar = false;
  @Input() whiteLabel = false;

  generated$ = new BehaviorSubject(false);
  error$ = new BehaviorSubject(false);

  @ViewChild('qrImage', {static: true}) qrImage: ElementRef;

  constructor(
    @Optional() @Inject(BASE_URL) private _baseUrl: string,
    private _snackbar: MatSnackBar,
    private _translate: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.useAvatar) {
      this._fetchQR().subscribe();
    }
  }

  ngOnInit() {
    this._fetchQR().subscribe();

    this.error$.pipe(untilDestroyed(this)).subscribe((error) => {
      if (error) {
        this._snackbar.open(this._translate.instant('error.unknown'), undefined, {
          duration: 2000,
        });
      }
    });
  }

  download() {
    this._fetchQR(true).subscribe();
  }

  private _fetchQR(download = false) {
    this.generated$.next(false);
    this.error$.next(false);

    return generateQR$(
      download ? 2500 : 256,
      this.url,
      this.useAvatar
        ? this.avatarUrl
        : this.whiteLabel
          ? undefined
          : `${this._baseUrl}/assets/icons/custom/aztrixlogo_green-border.svg`,
      this.useAvatar
    ).pipe(
      first(),
      map((dataUrl) => {
        if (download) {
          downloadDataUrl(dataUrl, `${this.name}-qr.png`);
        } else {
          this.qrImage.nativeElement.src = dataUrl;
        }
      }),
      tap({
        next: () => {
          this.generated$.next(true);
        },
        error: () => {
          this.error$.next(true);
          this.generated$.next(true);
        },
      })
    );
  }
}
