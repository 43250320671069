<div class="title-wrapper">
  <h3 class="title">{{ 'label.visibility' | translate }}</h3>
  <button axButton icon-button (click)="close()">
    <ax-icon class="icon" name="close"></ax-icon>
  </button>
</div>

<p>{{ alistUrl }}</p>

<div class="toggle-setting">
  <mat-slide-toggle
    *ngIf="alistActiveForm$ | async as alistActiveForm"
    class="active"
    [formControl]="alistActiveForm"
    color="primary"
    labelPosition="before"
    (click)="activationWarning()"
  >
    {{ 'label.public' | translate }}
  </mat-slide-toggle>
</div>
