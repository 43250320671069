import {Component, Input} from '@angular/core';
import {PlatformService} from '@aztrix/helpers';
import {TranslateService} from '@aztrix/translate';
import {map} from 'rxjs';

@Component({
  selector: 'ax-apple-wallet-button',
  templateUrl: './apple-wallet-button.component.html',
  styleUrls: ['./apple-wallet-button.component.scss'],
})
export class AppleWalletButtonComponent {
  @Input() walletResult: string;
  @Input() fileName: string;

  icon$ = this._translateService.lang$.pipe(map((lang) => `brands:apple-wallet-${lang}`));

  constructor(
    private _translateService: TranslateService,
    private _platformService: PlatformService
  ) {}

  addToAppleWallet() {
    const blob = new Blob([this.walletResult], {type: 'application/vnd.apple.pkpass'});
    const downloadURL = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = `aztrix-${this.fileName}.pkpass`;
    anchor.href = downloadURL;
    anchor.click();
  }

  get isApple(): boolean {
    return this._platformService.isApple();
  }
}
