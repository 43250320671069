import {FormService} from '@alist/helpers/services/form.service';
import {AListService} from '@aztrix/list/store/alist/alist.service';
import {TierService} from '@aztrix/list/store/tier/tier.service';
import {Component, ElementRef, Input} from '@angular/core';
import {OverlayService} from '@aztrix/components/overlay';
import {FeatureType, isFeatureAllowed} from '@aztrix/helpers';
import {combineLatest, first, take} from 'rxjs';

import {TierWarningComponent} from '../tier-warning/tier-warning.component';

@Component({
  selector: 'al-alist-active',
  templateUrl: './alist-active.component.html',
  styleUrls: ['./alist-active.component.scss'],
})
export class AlistActiveComponent {
  @Input() alistUrl: string;

  alistActiveForm$ = this._form.clientAlistActiveForm$;

  constructor(
    private _elementRef: ElementRef,
    private _form: FormService,
    private _overlayService: OverlayService,
    private _tier: TierService,
    private _alist: AListService
  ) {}

  activationWarning() {
    combineLatest([
      this._tier.currentTier$.pipe(first()),
      this._alist.clientAlist$.pipe(take(2)),
      this._alist.clientAlists$.pipe(take(2)),
    ]).subscribe(([tier, alist, alists]) => {
      if (
        isFeatureAllowed(tier, FeatureType.MULTIPLE_ALISTS) ||
        alists.filter((a) => a.type === alist?.type).filter((a) => !!a.active).length <= 1
      ) {
        return;
      }

      this.alistActiveForm$.pipe(first()).subscribe((alistActiveForm) => {
        if (alistActiveForm?.value) {
          alistActiveForm.setValue(false);
          const modalRef = this._overlayService.createModal(
            this._elementRef,
            TierWarningComponent,
            {
              init: (modal) => {
                modal.cancel.pipe(first()).subscribe(() => {
                  this._overlayService.closeModal(modalRef);
                });
                modal.upgrade.pipe(first()).subscribe(() => {
                  this._overlayService.closeModal(modalRef);
                });
              },
            }
          );
        }
      });
    });
  }

  close() {
    this._overlayService.closeModal();
  }
}
