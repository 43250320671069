import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {IconsModule} from '@aztrix/icons';

import {AlistAppleWalletButtonComponent} from './alist-apple-wallet-button/alist-apple-wallet-button.component';
import {AppleWalletButtonComponent} from './apple-wallet-button.component';
import {ProfileAppleWalletButtonComponent} from './profile-apple-wallet-button/profile-apple-wallet-button.component';

@NgModule({
  declarations: [
    AppleWalletButtonComponent,
    ProfileAppleWalletButtonComponent,
    AlistAppleWalletButtonComponent,
  ],
  imports: [CommonModule, IconsModule],
  exports: [
    AppleWalletButtonComponent,
    ProfileAppleWalletButtonComponent,
    AlistAppleWalletButtonComponent,
  ],
})
export class AppleWalletButtonModule {}
