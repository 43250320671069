import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {AppleWalletButtonModule} from '@aztrix/components/apple-wallet-button';
import {ButtonModule} from '@aztrix/components/button';
import {CopyTextModule} from '@aztrix/components/copy-text';
import {ForwardButtonModule} from '@aztrix/components/forward-button';
import {OptionPanelModule} from '@aztrix/components/option-panel';
import {QrCodeModule} from '@aztrix/components/qr-code';
import {SpinnerModule} from '@aztrix/components/spinner';
import {HelperPipesModule} from '@aztrix/helpers';
import {IconsModule} from '@aztrix/icons';
import {TranslatePipe} from '@aztrix/translate';

import {UpgradeTierButtonModule} from '../upgrade-tier-button/upgrade-tier-button.module';
import {EmailSignatureModule} from './email-signature/email-signature.module';
import {ShareComponent} from './share.component';

@NgModule({
  declarations: [ShareComponent],
  imports: [
    CommonModule,
    IconsModule,
    ButtonModule,
    HelperPipesModule,
    QrCodeModule,
    MatSlideToggleModule,
    SpinnerModule,
    CopyTextModule,
    MatSnackBarModule,
    OptionPanelModule,
    AppleWalletButtonModule,
    UpgradeTierButtonModule,
    ForwardButtonModule,
    EmailSignatureModule,
    TranslatePipe,
  ],
  exports: [ShareComponent],
})
export class ShareModule {}
