import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ButtonModule} from '@aztrix/components/button';
import {IconsModule} from '@aztrix/icons';

import {ForwardButtonComponent} from './forward-button.component';

@NgModule({
  declarations: [ForwardButtonComponent],
  imports: [CommonModule, IconsModule, RouterModule, ButtonModule],
  exports: [ForwardButtonComponent],
})
export class ForwardButtonModule {}
