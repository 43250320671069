<ax-option-panel selected="qr">
  <ax-option value="qr" icon="qrcode">
    <ax-qr-code
      [name]="(name$ | async) || undefined"
      [avatarUrl]="(aListAvatarUrl$ | async) || undefined"
      [url]="(aListUrl$ | async) || ''"
      [useAvatar]="useAvatar"
    ></ax-qr-code>

    <div
      class="avatar-setting"
      [ngClass]="{
        'is-allowed': (currentTier$ | async | isFeatureAllowed: FeatureType.QR_LOGO)
      }"
      *ngIf="aListAvatarUrl$ | async"
    >
      <span class="title">{{ 'forward.qr.avatar.option.title' | translate }}</span>
      <al-upgrade-tier-button
        class="tier"
        *ngIf="(currentTier$ | async | isFeatureAllowed: FeatureType.QR_LOGO) === false"
        filled
        (click)="close()"
        [featureType]="FeatureType.QR_LOGO"
      ></al-upgrade-tier-button>
      <span class="description">{{ 'forward.qr.avatar.option.message' | translate }}</span>
      <mat-slide-toggle
        class="slide"
        (toggleChange)="useAvatar = !useAvatar"
        [checked]="useAvatar"
        [disabled]="(currentTier$ | async | isFeatureAllowed: FeatureType.QR_LOGO) === false"
        color="primary"
        [aria-label]="(useAvatar ? 'label.disable' : 'label.enable') | translate"
      ></mat-slide-toggle>
    </div>

    <ax-alist-apple-wallet-button
      *ngIf="(alistType$ | async) === Type.BIO"
      class="apple-wallet"
      [alistId]="(alistId$ | async) || ''"
      [alistLanguageId]="(alistLanguageId$ | async) || ''"
      [alistName]="(name$ | async) || ''"
    ></ax-alist-apple-wallet-button>
  </ax-option>
  <ax-option value="copy" icon="content-copy">
    <ax-copy-text [value]="(aListUrl$ | async) || undefined"></ax-copy-text>
  </ax-option>
  <ax-option value="url" icon="open-in-new">
    <a
      axButton
      class="button"
      [attr.href]="aListUrl$ | async"
      target="_blank"
      filled
      color="primary"
      fullWidth
    >
      <ax-icon class="icon" name="open-in-new"></ax-icon>
      <span>{{ aListUrl$ | async }}</span>
    </a>
    <p class="title">{{ 'forward.social.title' | translate }}</p>
    <div class="forward-buttons" *ngIf="aListUrl$ | async as aListUrl">
      <ax-forward-button
        class="forward-button"
        [forwardType]="ForwardType.FACEBOOK"
        [url]="aListUrl"
      >
      </ax-forward-button>
      <ax-forward-button
        class="forward-button"
        [forwardType]="ForwardType.TWITTER"
        [url]="aListUrl"
      >
      </ax-forward-button>
      <ax-forward-button
        class="forward-button"
        [forwardType]="ForwardType.LINKEDIN"
        [url]="aListUrl"
      >
      </ax-forward-button>
      <ax-forward-button
        class="forward-button"
        [forwardType]="ForwardType.WHATSAPP"
        [url]="aListUrl"
      >
      </ax-forward-button>
      <ax-forward-button class="forward-button" [forwardType]="ForwardType.EMAIL" [url]="aListUrl">
      </ax-forward-button>
    </div>
  </ax-option>
  <ax-option value="email-signature" icon="email-outline" *ngIf="(alistType$ | async) === Type.BIO">
    <al-email-signature
      [alistName]="(name$ | async) || ''"
      [alistUrl]="(aListUrl$ | async) || ''"
    ></al-email-signature>
  </ax-option>
</ax-option-panel>
