<p class="title">{{ 'profile.email-signature' | translate }}</p>
<div class="signature-content">
  <table #signatureContent style="border-collapse: collapse; margin: 20px 20px 20px 0">
    <tbody>
      <tr>
        <td>
          <table style="border-collapse: collapse">
            <tbody>
              <tr>
                <td
                  style="white-space: nowrap; padding: 0; line-height: 1.5"
                  *ngIf="organisaionName$ | async"
                >
                  <strong>
                    <ax-property-value-view
                      *ngIf="organisaionName$ | async as organisaionName"
                      [property]="organisaionName"
                    ></ax-property-value-view>
                  </strong>
                </td>
                <td
                  style="white-space: nowrap; padding: 0; line-height: 1.5"
                  *ngIf="firstName$ | async"
                >
                  <strong>
                    {{ (firstName$ | async)?.value }}
                    {{ (lastName$ | async)?.value }}
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
          <table style="border-collapse: collapse">
            <tbody>
              <tr>
                <td style="white-space: nowrap; padding: 0; line-height: 1.5">
                  <ax-property-value-view
                    *ngIf="email$ | async as email"
                    [property]="email"
                    [clickable]="true"
                  ></ax-property-value-view>
                </td>
                <ng-container *ngIf="mobile$ | async as mobile">
                  <td style="padding: 0 5px">|</td>
                  <td style="white-space: nowrap; padding: 0; line-height: 1.5">
                    <ax-property-value-view
                      [property]="mobile"
                      [clickable]="true"
                    ></ax-property-value-view>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
          <table style="border-collapse: collapse">
            <tbody>
              <tr>
                <td style="white-space: nowrap; padding: 0; line-height: 1.5">
                  {{ 'forward.link.text' | translate }}:
                  <a [href]="alistUrl">{{ viewURI$ | async }}/{{ alistName }}*</a>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ax-button
  (click)="copy()"
  [fullWidth]="true"
  [bigButton]="true"
  filled
  color="primary"
  icon="content-copy"
>
  <span *ngIf="(copied$ | async) === false">{{ 'label.copy' | translate }}</span>
  <span *ngIf="copied$ | async">{{ 'label.copied' | translate }}</span>
</ax-button>
