import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ButtonModule} from '@aztrix/components/button';
import {IconsModule} from '@aztrix/icons';
import {TranslatePipe} from '@aztrix/translate';

import {AlistActiveComponent} from './alist-active.component';

@NgModule({
  declarations: [AlistActiveComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconsModule,
    ButtonModule,
    MatSlideToggleModule,
    TranslatePipe,
  ],
  exports: [AlistActiveComponent],
})
export class AlistActiveModule {}
