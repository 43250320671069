<div class="qr-image">
  <ax-spinner *ngIf="(generated$ | async) === false"
    >{{ 'label.loading' | translate }}...</ax-spinner
  >
  <img
    class="image"
    #qrImage
    [ngStyle]="{display: (generated$ | async) ? 'inherit' : 'none'}"
    [attr.alt]="'forward.qr.description' | translate: {subject: 'label.page' | translate}"
  />
</div>

<div class="button-wrapper">
  <ax-button
    (click)="download()"
    [fullWidth]="true"
    [bigButton]="true"
    [loading]="(generated$ | async) === false"
    [disabled]="(generated$ | async) === false"
    filled
    color="primary"
    icon="download-outline"
  >
    <span>{{ 'label.download' | translate }}</span>
  </ax-button>
</div>
